<template>
  <v-container class="py-7">
    <template v-if="sale">
      <div class="heading mb-7">{{ sale.page_name }}</div>
      <div v-html="sale.content" class="custom-content mb-7"></div>
      <v-btn color="primary" block outlined large :to="{ name: 'Sales' }" exact
        >Вернуться назад</v-btn
      >
    </template>
  </v-container>
</template>

<script>
export default {
  name: "Sale",

  computed: {
    id() {
      return this.$route.params.id;
    },

    sale() {
      return this.$store.getters["sales/detail"](this.id);
    },
  },

  async created() {
    const { commit, dispatch } = this.$store;
    commit("overlay/update", { active: true });
    await dispatch("sales/loadDetail", this.id);
    commit("overlay/update", { active: false });
  },
};
</script>

<style lang="scss">
.custom-content {
  font-family: $body-font-family !important;

  > * {
    font-family: inherit !important;

    > * {
      font-family: inherit !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    max-width: 100%;
  }

  table {
    max-width: 100%;
    width: auto !important;
  }

  img {
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
    max-height: 240px;
  }
}
</style>
